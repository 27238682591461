// extracted by mini-css-extract-plugin
export var root = "AddNotification-module--root--agK47";
export var header = "AddNotification-module--header--mzJ0P";
export var iconContainer = "AddNotification-module--iconContainer--UHpFb";
export var actionContainer = "AddNotification-module--actionContainer--BSbUo";
export var newItemContainer = "AddNotification-module--newItemContainer--hWehJ";
export var imageContainer = "AddNotification-module--imageContainer--ujfGt";
export var detailContainer = "AddNotification-module--detailContainer--90KwM";
export var name = "AddNotification-module--name--LaNjt";
export var meta = "AddNotification-module--meta--pw5V1";
export var linkContainer = "AddNotification-module--linkContainer--377fB";
export var show = "AddNotification-module--show--jfMIV";
export var hide = "AddNotification-module--hide--hhDEQ";