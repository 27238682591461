// extracted by mini-css-extract-plugin
export var root = "MobileNavigation-module--root--v2k-y";
export var headerAuth = "MobileNavigation-module--headerAuth--GEt6O";
export var authLinkContainer = "MobileNavigation-module--authLinkContainer--bDcx4";
export var welcomeContainer = "MobileNavigation-module--welcomeContainer--lUcZu";
export var welcomeMessage = "MobileNavigation-module--welcomeMessage--L8Cdr";
export var previousLinkContainer = "MobileNavigation-module--previousLinkContainer---ITNs";
export var previousIcon = "MobileNavigation-module--previousIcon--VqaFM";
export var mobileNavContainer = "MobileNavigation-module--mobileNavContainer--Geg4B";
export var mobileLink = "MobileNavigation-module--mobileLink--BHyeM";
export var edgeLink = "MobileNavigation-module--edgeLink--g4zG5";
export var navFooter = "MobileNavigation-module--navFooter--igQVd";
export var logoutContainer = "MobileNavigation-module--logoutContainer--mZ8N5";