// extracted by mini-css-extract-plugin
export var root = "MiniCartItem-module--root--m9pxT";
export var imageContainer = "MiniCartItem-module--imageContainer--PNtRi";
export var detailsContainer = "MiniCartItem-module--detailsContainer--rZ5qZ";
export var metaContainer = "MiniCartItem-module--metaContainer--5QyX7";
export var closeContainer = "MiniCartItem-module--closeContainer--Hcqp0";
export var name = "MiniCartItem-module--name--5ciVc";
export var priceContainer = "MiniCartItem-module--priceContainer--ZN4d6";
export var meta = "MiniCartItem-module--meta--EsbWn";
export var size = "MiniCartItem-module--size--dIna0";
export var adjustItemContainer = "MiniCartItem-module--adjustItemContainer---zKtr";
export var price = "MiniCartItem-module--price--Ld3fh";